import React from "react"
import "../Styles/Components/Team.css";

import Miracle from '../Images/Miracle.png';
import Prisca from '../Images/Prisca.png';
import Goodness from '../Images/Goodness.png';
import Steve from '../Images/Stevenson.png';
import Eunice from '../Images/Eunice.png';
import Ken from '../Images/Kenneth.png';
import Kingsley from '../Images/Kingsley.png';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';


function Team() {  
  return (
    <div className="Team">
        <div id="teamtxt" style={{color: "white", textAlign: "center", fontFamily: "Plus Jakarta Sans"}}>OUR TEAM</div>
        <div id="teamwrp">
            <Swiper
                style={{justifyContent: "center", alignSelf: "center"}}
                effect={'coverflow'}
                grabCursor={false}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
                }}
                navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
            >
                <SwiperSlide style={{width: "250px"}}>
                <img src={Ken} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide style={{width: "250px"}}>
                <img src={Steve} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide style={{width: "250px"}}>
                <img src={Miracle} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide style={{width: "250px"}}>
                <img src={Prisca} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide style={{width: "250px"}}>
                <img src={Eunice} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide style={{width: "250px"}}>
                <img src={Kingsley} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide style={{width: "250px"}}>
                <img src={Goodness} alt="slide_image" />
                </SwiperSlide>

                <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                    <ion-icon name="arrow-back-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                </div>
            </Swiper>
            
            <section class="slider-container">
                <div class="slider-images pagination">
                    <div class="slider-img">
                        <img src={Kingsley} alt="1" />
                    </div>
                    <div class="slider-img">
                        <img src={Steve} alt="2" />
                    </div>
                    <div class="slider-img">
                        <img src={Miracle} alt="3" />
                    </div>
                    <div class="slider-img active">
                        <img src={Prisca} alt="4" />
                    </div>
                    <div class="slider-img">
                        <img src={Eunice} alt="5" />
                    </div>
                    <div class="slider-img">
                        <img src={Goodness} alt="6" />
                    </div>
                    <div class="slider-img">
                        <img src={Ken} alt="7" />
                    </div>
                </div>
            </section>
    </div>
    </div>
  )
}

export default Team;