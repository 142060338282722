import React from 'react';
import {Helmet} from "react-helmet";

import Join from '../Components/Join';
import Featured from '../Components/Featured';
import Footer from '../Components/Footer';
import Nav from '../Components/Nav';
import Team from '../Components/Team';

import headimg from '../Images/Header-right.png'
import gplay from '../Images/Google Play.png';
import appstr from '../Images/App Store.png'
import body1 from '../Images/Body1.png';
import body2 from '../Images/Body2.png';
import body3 from '../Images/Body3.png';
import body4 from '../Images/Body4.png';
import body5 from '../Images/Body5.png';
import body6 from '../Images/Body6.png';

import konga from '../Images/Konga.png';
import koyn from '../Images/Koyn.png';
import mozart from '../Images/Mozart.png';
import kwik from '../Images/Kwik.png';
import skill from '../Images/Skillup.png';
import live from '../Images/Livescore.png';
import ycard from '../Images/Yellow Card.png';
import prom from '../Images/Promoter.png';

import '../Styles/Pages/Home.css';

function Home() {
  return (
    <div className='row' id='Home'>
        <Helmet>
            <title>Home - AdMinting</title>
        </Helmet>
        <Nav/>
        <div className='row' id='header'>
            <div className='row'>
                <div className='col-6' id='headdt'>
                    <span id='admtxt'>Adminting</span>
                    <h1 style={{fontFamily: "Plus Jakarta Sans", color: "#14314E", fontWeight: "bold"}}>YOUR COMMUNITY<br id='break'/> BASED ADVERTISING</h1>
                    <p style={{fontFamily: "Plus Jakarta Sans", color: "#0F172A"}}>We are a market place for advertisers and an aggregator<br id='break1'/> of niched online communities and  digital Promoters,<br id='break1'/> enabling targeted, authentic, and cost-effective advertising<br id='break1'/> campaigns.</p>
                    <div id='dloadrow'>
                        <span>
                            <img alt='' src={appstr}/>
                        </span>
                        <span>
                            <img alt='' src={gplay}/>
                        </span>
                    </div>
                </div>
                <div className='col-6' id='hdimg'>
                    <img alt='' style={{width: "100%"}} src={headimg}/>
                </div>
            </div>
        </div>
        <div className='row' id='fetsec'>
            <h2>The Future of Advertising Starts Here</h2>
            <p>Adminting seamlessly connects advertisers with niche digital promoters,<br/> empowering businesses to achieve their advertising goals with ease.</p>
        </div>
        <div className='row' id='body1'>
            <h2>Our Features</h2>
            <div id="featrow" className='row'>
                <div className='col' id='colinit'>
                    <div id='wrapper'>
                        <img alt='' src={body1}/>
                        <span id="head">Easy Ad Creation</span>
                        <span id='bodtxt'>Create your Ads in seconds with our user-friendly platform. Whether you're an experienced marketer or a novice, our platform simplifies the ad creation process. No more complex procedures, just vibes and good energy. No Caps!</span>
                    </div>
                </div>
                <div className='col' id='colinit2'>
                    <div id='wrapper'>
                        <img alt='' src={body2}/>
                        <span id="head">Access to Our Network of Online Digital Community Promoters</span>
                        <span id='bodtxt'>Harness the power of our vibrant community of niche digital promoters. Connect with nano, micro and macro influencers who genuinely understand the assignment. Expand your reach and amplify your message through trusted voices that have a genuine connection.</span>
                    </div>
                </div>
            </div>
            <div id="featrow" className='row'>
                <div className='col' id='colinit'>
                    <div id='wrapper'>
                        <img alt='' src={body3}/>
                        <span id="head">Transparent Tracking Solution</span>
                        <span id='bodtxt'>Maintain control with our transparent tracking solution. Keep a real-time watch over your campaigns, access detailed analytics. You only pay for goals achieved– just straightforward, actionable insights.</span>
                    </div>
                </div>
                <div className='col' id='colinit2'>
                    <div id='wrapper'>
                        <img alt='' src={body4}/>
                        <span id="head">Precise Targeted Advertising</span>
                        <span id='bodtxt'>Reach your ideal audience with precision. Create custom campaigns that hit the mark every time.</span>
                    </div>
                </div>
            </div>
            <div id="featrow" className='row'>
                <div className='col' id='colinit'>
                    <div id='wrapper'>
                        <img alt='' src={body5}/>
                        <span id="head">Powerful Insights</span>
                        <span id='bodtxt'>Our platform provides in-depth reports and analytics to help you understand what works and what doesn’t. Make data-driven decisions to continuously improve your campaigns and drive better results.</span>
                    </div>
                </div>
                <div className='col' id='colinit2'>
                    <div id='wrapper'>
                        <img alt='' src={body6}/>
                        <span id="head">Multi-Platform Reach</span>
                        <span id='bodtxt'>Expand your reach across multiple platforms including social media, search engines, and More.</span>
                    </div>
                </div>
            </div>
        </div>
        <Team/>
        <div className='row' id='body3'>
            <h2>OUR PARTNERS</h2>
            <div id='stl'>
                <span className='col-2'>
                    <img alt='' src={ycard}/>
                </span>
                <span className='col-2'>
                    <img alt='' src={konga}/>
                </span>
                <span className='col-2'>
                    <img alt='' src={skill}/> 
                </span>
                <span className='col-2'>
                    <img alt='' src={koyn}/>
                </span>
                <span className='col-2'>
                    <img alt='' src={mozart}/>
                </span>
                <span className='col-2'>
                    <img alt='' src={live}/>
                </span>
                <span className='col-2'>
                    <img alt='' src={kwik}/>
                </span>
            </div>
        </div>
        <div className='row' id='body4'>
            <div className='col' style={{alignSelf: "center"}}>
                <span>
                    <h2>Meet Our Promoter of the Month</h2>
                    <p>Every month, we shine a spotlight on one of our top promoters, asking them about their experiences and how AdMinting has helped them grow.</p>
                </span>
            </div>
            <div className='col'>
                <img alt='' src={prom}/>
                <div className='card'>
                    <span id='spt' style={{color: "#14314E", fontWeight: "800"}}>Promoter Spotlight</span>
                    <div id='parrow' className='row'>
                        <p>"AdMinting has revolutionized the way I approach digital promotion. Their tools and community support have helped me achieve my goals faster than I ever thought possible."</p>
                    </div>
                    <span id='promname'>-Hardid Malaikee</span>
                </div>
            </div>
        </div>
        <Join/>
        <Featured/>
        <Footer/>
    </div> 
  )
}

export default Home;