import React from 'react';
import {Helmet} from "react-helmet";

import Nav from '../Components/Nav'
import Join from '../Components/Join';
import Featured from '../Components/Featured';
import Footer from '../Components/Footer';

import '../Styles/Pages/FAQs.css';

function FAQs() {
  return (
    <div className='FAQs'>
      <Helmet>
        <title>FAQs - AdMinting</title>
      </Helmet>
      <Nav/>
      <div id='faqhead'>
        <h2>Adminting FAQ</h2>
        <p>have a search?</p>
      </div>
      <div id='searchbx'>
        <input type='search' placeholder='Search'/>
      </div>
      <div id='faqmain'>
        <div id='wrap'>
          <div className='col-3'>
            <h4>Topic Navigation</h4>
            <span>Topic Placeholder</span>
            <span>Topic Placeholder</span>
            <span>Topic Placeholder</span>
            <span>Topic Placeholder</span>
            <span>Topic Placeholder</span>
          </div>
          <div className='col-9' style={{fontFamily: "inter"}}>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    What is AdMinting?
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting is the first community-driven advertising platform that connects advertisers with niche digital promoters. Our platform offers easy ad creation, transparent tracking, targeted advertising, and powerful insights to help you achieve quality results.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How do I create an ad on AdMinting?
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Creating an ad on AdMinting is simple and intuitive.<br/>
                    First Download the app on play store or apple store
                    Signup and Create an account with adminting, Fund your Wallet, click on Create a Task,  setup your ad,select niche and location, upload your AD design, Select an AD goal, drop the link to your ad goal,drop the link to similar ad assets (for advertisers that want to use the community Promoters), You can chose to boost your ad using the community, pay for the ads, and watch your ad convert.

                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Who are the digital promoters on AdMinting?
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Our digital promoters are, online community owners, influencers and content creators who have large following and  a genuine connection with their followers. They are selected based on their niche, engagement levels, and ability to effectively promote your brand to their audience.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    How does AdMinting ensure transparency in ad tracking?
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting provides a transparent tracking solution that allows you to monitor your campaigns in real-time. You can access detailed analytics and reports to see exactly how your ads are performing and where your budget is being spent.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    What targeting options are available on AdMinting?
                  </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting offers niche targeting based on type of community you want to access, and their locations. This ensures that your ads reach the right people at the right time, maximizing your campaign's impact.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    How can I measure the success of my ad campaigns on AdMinting?
                  </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Our platform provides powerful advertising insights with in-depth reports and analytics. You can track key performance indicators (KPIs), such as impressions,  engagement, reach and conversions., to understand the effectiveness of your campaigns and make data-driven decisions.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    How do I get started with AdMinting?
                  </button>
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Getting started with AdMinting is easy. Download our app via IOS or Android store, to sign up on our platform, create your account, and follow the prompts to set up your first ad campaign. Our support team is also available to assist you at every step of the way.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    What types of businesses can benefit from AdMinting?
                  </button>
                </h2>
                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting is ideal for any business looking to enhance its online presence and reach a targeted audience. Whether you're a small business, a startup, or a large corporation, our platform can help you connect with the right digital promoters and achieve your advertising goals.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingNine">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    How do I join the AdMinting digital promoter community?
                  </button>
                </h2>
                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    If you're an online community owner,  an influencer or content creator interested in joining our community, you can sign up on our platform and create a promoter profile. Once approved, you can start participating in ad campaigns and earning rewards for promoting brands.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    How can I contact AdMinting for support?
                  </button>
                </h2>
                <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    For any questions or support, you can reach out to our customer service team through our website's contact form, email us at support@adminting.com, or call our support hotline. We're here to help!
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingEleven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                    Is there a minimum budget required to start an ad campaign on AdMinting?
                  </button>
                </h2>
                <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting is designed to be accessible for businesses of all sizes. While there is no strict minimum budget, we recommend starting with an amount that allows you to test and optimise your campaigns effectively. Our minimum Target is  5 objective and maximum is 200 task objective
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwelve">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                    Can I run multiple ad campaigns simultaneously on AdMinting?
                  </button>
                </h2>
                <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Yes, you can run multiple ad campaigns simultaneously. Our platform allows you to manage and track each campaign individually, giving you the flexibility to target different audiences and achieve diverse marketing goals.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThirteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                    How do payments work on AdMinting?
                  </button>
                </h2>
                <div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                  Payments for ad campaigns can be made through funding your adminting wallet with various secure methods, including credit/debit cards and online payment gateways.<br/>
                  Promoters earn their rewards, once a task goal is met, Promoters can click on the ad they promoted, and request for their payment, which is processed automatically to their wallet, and they can also withdraw to their direct bank accounts.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFourteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                    What types of ads can I create on AdMinting?
                  </button>
                </h2>
                <div id="collapseFourteen" class="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting at the moment only supports Image Ad formats, subsequently,we will be supporting  video ads, carousel ads. We also run Awareness ads; such as reach, impressions, Engagement ads such as Views, clicks,website traffic,  and conversion ads such as, Lead generations, downloads, Streams etc.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFiveteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiveteen" aria-expanded="false" aria-controls="collapseFiveteen">
                    How does AdMinting protect my data?
                  </button>
                </h2>
                <div id="collapseFiveteen" class="accordion-collapse collapse" aria-labelledby="headingFiveteen" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting is committed to protecting your data and privacy. We implement robust security measures and comply with relevant data protection regulations to ensure that your information is safe and secure on our platform.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Can I customize my ad campaigns on AdMinting?
                  </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Yes, AdMinting offers extensive customization options for your ad campaigns. You can tailor your ads to specific audiences, set your campaign objectives, budget, and schedule, and adjust your targeting parameters to achieve the best results.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    What kind of support does AdMinting offer to new users?
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    AdMinting provides comprehensive support to new users, including onboarding tutorials, a detailed user manual, and access to our customer support team. We're dedicated to helping you succeed and are available to assist you with any questions or issues you may encounter.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    How does AdMinting handle disputes or issues between advertisers and promoters?
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    In the rare event of a dispute or issue, AdMinting has a dedicated support team to mediate and resolve conflicts. We strive to ensure fair and transparent communication between advertisers and promoters to maintain a positive and productive community.
                  </div>
                </div>
              </div>             
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Can I cancel or modify my ad campaign after it has started?
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    No,but you can Pause your Campaign but not cancel, you cannot modify a campaign, rather you can decide to create another one to meet your new objective.
                    You can also Extend your ad once it meets its objectives.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Join/>
      <Featured/>
      <Footer/>
    </div>
  )
}

export default FAQs;