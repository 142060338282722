import React from 'react';

import logo from '../Logo/adm logo.svg';

import '../Styles/Components/Nav.css';

function Nav() {
  return (
    <div className='Navsec'>
      <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{fontFamily: "Roboto", fontWeight: "700"}}>
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img alt='' src={logo}/>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
              <ul className="navbar-nav" id='midnav'>
                <li className="nav-item">
                  <a href="/" className="nav-link active" aria-current="page">Home</a>
                </li>
                <li className="nav-item">
                  <a href="/faqs" className="nav-link">FAQs</a>
                </li>
                <li className="nav-item">
                  <a target='_blank' href="https://blog.adminting.com" className="nav-link" rel="noreferrer">Knowledge base</a>
                </li>
                <li className="nav-item">
                  <a href="/calculator" className="nav-link">Calculator</a>
                </li>
              </ul>
            <ul className="navbar-nav" id='endnav'>
              <button style={{color: "white"}} href="/" className="nav-link active nav-item" aria-current="page">Download App</button>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Nav;