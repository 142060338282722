import React from 'react';
import {Helmet} from "react-helmet";

import Nav from "../Components/Nav";
import Footer from '../Components/Footer';

import '../Styles/Pages/Privacy.css';

function Privacy() {
  return (
    <div className='Privacy'>
        <Helmet>
            <title>Privacy - AdMinting</title>
        </Helmet>
        <Nav/>
        <div className='row' id='prihead'>
            <h2>Privacy Policy</h2>
        </div>
        <div id='termbd'>
          <h2 style={{textAlign: "center"}}>While the privacy policy provided includes many of the key elements necessary to comply with privacy regulations, privacy laws and best practices can vary by region and evolve over time. <br/>Here are some considerations to ensure that the privacy policy is up-to-date with the latest requirements:</h2>
          <strong>1. GDPR Compliance (for EU users)</strong> 
          <ol>Legal Basis for Processing**: Clearly state the legal basis for processing personal data.</ol>
          <ol>Data Protection Officer (DPO): If applicable, mention the appointment of a DPO.</ol>
          <ol>Data Subject Rights: Include detailed information about data subject rights (e.g., right to access, right to rectification, right to erasure, right to data portability).</ol>
          <ol>International Data Transfers**: Explain any data transfers outside the EU and the safeguards in place.</ol>

          <strong>2. CCPA Compliance (for California residents)</strong>
          <ol>Categories of Personal Information Collected: Specify the categories of personal information collected in the last 12 months.</ol>
          <ol>Categories of Information Disclosed/Sold: List categories of personal information disclosed for business purposes or sold.</ol>
          <ol>Right to Opt-Out: Provide a clear method for users to opt-out of the sale of their personal information.</ol>
          <ol>Non-Discrimination: Assure users that they will not face discrimination for exercising their privacy rights.</ol>
          
          <strong>3. Nigerian Data Protection Regulation (NDPR)</strong>
          <ol>Data Collection Purpose: Clearly state the purpose of data collection.</ol>
          <ol>Third-Party Disclosure: Explain the circumstances under which personal data may be disclosed to third parties.</ol>
          <ol>Data Subject Rights: Detail the rights of data subjects under NDPR.</ol>
          <ol>Cookies and Tracking Technologies: Provide detailed information about the use of cookies and similar tracking technologies.</ol>
          <ol>Data Breach Notification: Outline your procedures for notifying users in the event of a data breach.</ol>
          <ol>Consent Management: Explain how you obtain and manage user consent for data processing.</ol>

          <strong>Here is a revised privacy policy that incorporates these considerations:</strong>
            
          <p style={{fontWeight: "bold", fontSize: "18px"}}>This Privacy Policy describes how AdMinting ("we", "us", or "our") collects, uses, and shares information when you use our mobile application ("App") and services. Protecting your privacy is important to us, and we are committed to safeguarding your personal information. Please read this Privacy Policy carefully to understand how we handle your information.</p>
          
          <strong>1. Information We Collect</strong>

          <h6 style={{fontWeight: "bold"}}>1.1. Information You Provide: </h6>
          <ol>When you register on our App or interact with our services, we may collect personal information such as your name, email address, contact details, and payment information.</ol>
          
          <h6 style={{fontWeight: "bold"}}>1.2. User Content</h6>
          <ol>We may collect content that you create, upload, or share on our platform, including posts, images, and videos.</ol>

          <h6 style={{fontWeight: "bold"}}>1.3. Automatically Collected Information</h6>
          <ol>When you use our App, we may automatically collect certain information, including your device information (e.g., device type, operating system), IP address, location data, and usage data (e.g., app usage patterns, interactions).</ol>

          <h6 style={{fontWeight: "bold"}}>1.4. Cookies and Tracking Technologies</h6>
          <ol>We use cookies and similar tracking technologies to collect information about your interactions with our App and to improve user experience. You can manage your cookie preferences through your device settings.</ol>

          <strong>2. Use of Information</strong>

          <h6 style={{fontWeight: "bold"}}>2.1. Providing Services</h6>
          <ol>We use the collected information to provide and improve our services, including facilitating connections between advertisers and users, processing payments, and optimizing user experience.</ol>
          
          <h6 style={{fontWeight: "bold"}}>2.2. Communications</h6>
          <ol>We may use your contact information to communicate with you about our services, promotions, and updates. You can opt-out of receiving promotional communications at any time.</ol>

          <h6 style={{fontWeight: "bold"}}>2.3. Analytics and Personalization</h6>
          <ol>We analyze usage data to understand how users interact with our App and to personalize content and advertisements.</ol>

          <h6 style={{fontWeight: "bold"}}>2.4. Legal Compliance</h6>
          <ol>We may use your information to comply with applicable laws, regulations, or legal processes.</ol>

          <strong>3. Legal Basis for Processing (for EU users)</strong>
          <h6 style={{fontWeight: "bold"}}>3.1 We process personal data based on the following legal grounds:</h6>
          <ol> - Your consent</ol>
          <ol> - Performance of a contract</ol>
          <ol> - Compliance with legal obligations</ol>
          <ol> - Legitimate interests pursued by us or a third party</ol>

          <strong>4. Information Sharing</strong>
          <ol><strong>4.1. With Advertisers:</strong>We share certain information with advertisers and partners to facilitate advertising campaigns and collaborations.</ol>
          <ol><strong>4.2. Service Providers</strong>We may share information with third-party service providers who assist us in operating our App and providing services.</ol>
          <ol><strong>4.3. Legal Requirements</strong>We may disclose information in response to valid legal requests or to protect our rights, property, or safety, or the rights, property, or safety of others.</ol>
          <ol><strong>4.3. International Data Transfers</strong>If we transfer your data outside the EU, we will ensure appropriate safeguards are in place to protect your data, such as standard contractual clauses or Privacy Shield certification.</ol>

          <strong>5. Data Security</strong>
          <ol>5.1 We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</ol>

          <strong>6. Data Retention</strong>
          <ol>6.1 We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</ol>

          <strong>7. Children's Privacy</strong>
          <ol>7.1 Our services are not directed to individuals under the age of 18. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us, and we will take steps to remove such information.</ol>

          <strong>8. Your Rights</strong>
          <ol>8.1 You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to certain processing activities.</ol>

          <strong>9. Data Breach Notification</strong>
          <ol>9.1 In the event of a data breach, we will notify affected users and the appropriate authorities as required by applicable law.</ol>

          <strong>10. Changes to Privacy Policy</strong>
          <ol>10.1 We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on this page.</ol>

          <strong>11. Contact Us</strong>
          <ol>11.1 If you have any questions or concerns about our Privacy Policy or practices, please contact us at support@adminting.com</ol>

          <h2>By using our App, you consent to the collection, use, and sharing of your information as described in this Privacy Policy. If you do not agree with this Privacy Policy, please do not use our App.</h2>
          <h2 style={{textAlign: "center"}}>This Privacy Policy was last updated on JULY 2024</h2>
        </div>
        <Footer/>
    </div>
  )
}

export default Privacy