import React from 'react';
import {Helmet} from "react-helmet";

import Nav from "../Components/Nav";
import Footer from '../Components/Footer';

import '../Styles/Pages/Privacy.css';

function Commguide() {
  return (
    <div className='Privacy'>
        <Helmet>
            <title>Community Guidelines - AdMinting</title>
        </Helmet>
        <Nav/>
        <div className='row' id='prihead'>
            <h2>Community Guidelines</h2>
        </div>
        <div id='termbd'>
          <h2 style={{textAlign: "center", padding: "0px 2%"}}>As part of the AdMinting community, we ask all Users to adhere to the following guidelines:</h2>
          <ol><strong>Transparency:</strong>Be transparent and honest about sponsored content and partnerships. Clearly disclose when content is part of an advertising campaign.</ol>

          <ol><strong>Respect:</strong>Treat others with respect and professionalism. Avoid harassment, discrimination, or offensive behavior.</ol>
          
          <ol><strong>Compliance:</strong>Follow all applicable laws and regulations, including advertising standards and guidelines.</ol>

          <ol><strong>Authenticity:</strong>Only promote products or services that align with your values and that you genuinely believe in.</ol>
          <ol><strong>Quality:</strong>Maintain quality standards for content shared on your platforms. Uphold integrity in your promotional activities.</ol>

          <ol><strong>Responsibility:</strong>Take responsibility for the content you share and the impact it may have on your audience.</ol>
          <ol><strong>Safety:</strong>Protect the safety and privacy of your audience. Avoid sharing sensitive or inappropriate content.</ol>

          <ol><strong>AdFraud: avoid manipulating campaign results as such will lead to banning the account.</strong></ol>

          <ol><strong>We don't promote arms, drugs, abusive or racist content.</strong></ol>
          <ol><strong>Failure to comply with these guidelines may result in account suspension or termination. AdMinting reserves the right to enforce these guidelines and take appropriate action against violators.</strong></ol>

          <h2 style={{textAlign: "center", padding: "0px 2%"}}>By using the AdMinting platform, you agree to uphold these community guidelines and contribute positively to our community.</h2>
        </div>
        <Footer/>
    </div>


  )
}

export default Commguide;