import React from 'react';

import tpoint from '../Images/techpoint.png';
import guard from '../Images/guardian.png';
import tcrunch from '../Images/techcrunch.png';

import '../Styles/Components/Featured.css';

function Featured() {
  return (
    <div className='row' id='body7'>
        <p>As featured in</p>
        <span>
            <img alt='' src={tpoint}/>
            <img alt='' src={guard}/>
            <img alt='' src={tcrunch}/>
        </span>
    </div>
  )
}

export default Featured;