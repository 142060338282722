import React, {useState} from 'react';
import {Helmet} from "react-helmet";

import Nav from '../Components/Nav';
import Join from '../Components/Join';
import Footer from '../Components/Footer';

import '../Styles/Pages/Calculator.css';

function Calculator() {

    const [firstElement, firstElementShow] = useState(true);
    const [secondElement, secondElementShow] = useState(false);
    const [buttonActive, setButtonActive] = React.useState("A");

    let [selectedAd, setSelectedAd] = useState();
    let [target, setTarget] = useState()

    let [selectedAd2, setSelectedAd2] = useState();
    let [target2, setTarget2] = useState()

  return (
    <div className='Calculator'>
        <Helmet>
            <title>Calculator - AdMinting</title>
        </Helmet>
        <Nav/>
        <div style={{width: "100%", display: "grid"}}>
            <div className='row' id='calchead'>
                <h2>Calculate Your Ad Revenue and Spending</h2>
                <p>Welcome to our Ad Revenue Calculator – your ultimate tool to forecast advertising success and optimize your budget. Whether you're planning your next campaign or analyzing past performance, our calculator helps you make informed decisions.</p>
            </div>
            <div className='row' id='calcwrap'>
                <div id='calc'>
                    <div id='toggle'>
                        <span style={{ backgroundColor: (buttonActive !== "B" ? "white" : "transparent") }} onClick={() => {setButtonActive("A"); secondElementShow(false);firstElementShow(true)}}>Advertiser</span>
                        <span style={{ backgroundColor: (buttonActive !== "A" ? "white" : "transparent") }} onClick={() => {setButtonActive("B"); secondElementShow(true);firstElementShow(false)}}>Promoter</span>
                    </div>
                    { firstElement ?
                        <div className='row' id='rwstr' style={{display: "flex", marginTop: "5%"}}>
                            <form>
                                <div className='col-6' id='rw1' style={{textAlign: "left"}}>
                                    <div id='inputwrap'>
                                        <label>Ad Category</label>
                                        <select id="Ads" onChange={(e) => setSelectedAd(e.target.value)} style={{padding: "0px"}}>
                                            <option></option>
                                            <option value="500">Download</option>
                                            <option value="500">Signs ups</option>
                                            <option value="700">Pay</option>
                                            <option value="200">View</option>
                                            <option value="200">Watch</option>
                                            <option value="200">Click</option>
                                            <option value="50">Reach</option>
                                        </select>
                                    </div>
                                    <div id='inputwrap'>
                                        <label>Ad Target</label>
                                        <input type="number" onChange={(e)=>setTarget(e.target.value)}/>
                                    </div>
                                    <div className='row' id='btnrow' style={{display: "grid"}}>
                                        <button style={{margin: "20px 20px",border: "2px solid #14314E", fontFamily: "Roboto", borderRadius: "10px", fontWeight: "500", fontSize: "25px", padding: "15px", color: "#14314E", textAlign: "center"}} type='reset'>Reset Calculator</button>
                                    </div>
                                </div>
                                <div className='col-6' style={{backgroundImage: "linear-gradient(to right, #338BC2, #DFFEE6)"}}>
                                    <span style={{marginBottom: "10px", color: "white", fontFamily: "inter", fontSize: "30px", fontWeight: "700"}}>Ad Cost</span>
                                    <div style={{marginBottom: "10px", color: "#20102B", borderRadius: "10px", backgroundColor: "white", margin: "10px 25%", padding: "10px", fontSize: "30px", fontWeight: "600", fontFamily: "inter"}}>NGN {selectedAd && target ? Number(selectedAd) * target : 0}</div>
                                    <p style={{color: "#0F172A", fontFamily: "Plus Jakarta Sans", fontWeight: "400", fontSize: "20px"}}><strong>Disclaimer:</strong> Please be aware that the results are derived from a set of preset criteria and simulated predictions, some of which cannot be guaranteed by AdMinting.</p>
                                </div>
                            </form>
                        </div>
                    : "" }
                    { secondElement ? 
                        <div className='row' id='rwstr' style={{display: "flex", marginTop: "5%"}}>
                            <form>
                                <div className='col-6' id='rw1' style={{textAlign: "left"}}>
                                    <div id='inputwrap'>
                                        <label>Ad Category</label>
                                            <select id="Ads" onChange={(e) => setSelectedAd2(e.target.value)} style={{padding: "0px"}}>
                                                <option></option>
                                                <option value="200">Download</option>
                                                <option value="200">Signs ups</option>
                                                <option value="500">Pay</option>
                                                <option value="80">View</option>
                                                <option value="80">Watch</option>
                                                <option value="80">Click</option>
                                                <option value="20">Reach</option>
                                            </select>
                                    </div>
                                    <div id='inputwrap'>
                                        <label>Ad Target</label>
                                        <input type="number" onChange={(e)=>setTarget2(e.target.value)}/>
                                    </div>
                                    <div className='row' id='btnrow' style={{display: "grid"}}>
                                        <button  style={{margin: "20px 20px",border: "2px solid #14314E", fontFamily: "Roboto", borderRadius: "10px", fontWeight: "500", fontSize: "25px", padding: "15px", color: "#14314E", textAlign: "center"}} type='reset'>Reset Calculator</button>
                                    </div>
                                </div>
                                <div className='col-6' style={{backgroundImage: "linear-gradient(to right, #338BC2, #DFFEE6)"}}>
                                    <span style={{marginBottom: "10px", color: "white", fontFamily: "inter", fontSize: "30px", fontWeight: "700"}}>Ad Earning</span>
                                    <div style={{marginBottom: "10px", color: "#20102B", borderRadius: "10px", backgroundColor: "white", margin: "10px 25%", padding: "10px", fontSize: "30px", fontWeight: "600", fontFamily: "inter"}}>NGN {selectedAd2 && target2 ? Number(selectedAd2) * target2 : 0}</div>
                                    <p style={{color: "#0F172A", fontFamily: "Plus Jakarta Sans", fontWeight: "400", fontSize: "20px"}}><strong>Disclaimer:</strong> Please be aware that the results are derived from a set of preset criteria and simulated predictions, some of which cannot be guaranteed by AdMinting.</p>
                                </div>
                            </form>
                        </div>   
                    : ""}
                </div>
            </div>
        </div>
        <Join/>
        <Footer/>
    </div>
  )
}

export default Calculator;