import React from 'react';

import logo2 from '../Logo/ADM.png';
import '../Styles/Components/Footer.css';

function Footer() {
  return (
    <>
        <div className='row' id='footer'>
            <div className='col-4' id='fcol'>
                <div style={{display: "grid"}}>
                    <img alt='' src={logo2}/>
                    <p id='abt'>AdMinting is a market place for advertisers and an aggregator of NICHED digital communities and influencers</p>
                </div>
            </div>
            <span id='cl2sp'>
                <div className='col-2' id='midcol' style={{inlineSize: "auto"}}>
                    <div style={{display: "grid"}}>
                        <h3>Company</h3>
                        <a target='_blank' href='/about'>About Us</a>
                        <a target='_blank' href='/faqs'>FAQs</a>
                        <a target='_blank' href='https://blog.adminting.com' rel="noreferrer">Blog</a>
                        <a target='_blank' href='/Calculator'>Calculator</a>
                    </div>
                </div>
                <div className='col-2' id='midcol' style={{inlineSize: "auto"}}>
                    <div style={{display: "grid"}}>
                        <h3>Legal</h3>
                        <a target='_blank' href='/terms'>Terms</a>
                        <a target='_blank' href='/privacy'>Privacy</a>
                        <a target='_blank' href='/community_guidelines'>Community</a>
                    </div>
                </div>
            </span>
            <div className='col-4' id='ftlascol' style={{inlineSize: "auto"}}>
                <div style={{display: "grid"}}>
                    <p id='fllw' style={{color: "#000000", fontFamily: "Roboto", fontWeight: "600"}}>Follow Us</p>
                    <span style={{display: 'flex'}}>
                        <a target='_blank' href='https://www.youtube.com/@adminting4062' rel="noreferrer">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                        <a target='_blank' href='https://web.facebook.com/Admintingng' rel="noreferrer">
                            <i class="fa-brands fa-facebook-f"></i>
                        </a>
                        <a target='_blank' href='https://www.tiktok.com/@adminting' rel="noreferrer">
                            <i class="fa-brands fa-tiktok"></i>
                        </a>
                        <a target='_blank' href='https://www.instagram.com/ad_minting_hq' rel="noreferrer">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                        <a target='_blank' href='https://www.linkedin.com/company/adminting-ltd' rel="noreferrer">
                            <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                    </span>
                </div>
                <div style={{display: "grid", fontSize: "20px", fontWeight: "550", fontFamily: "DM Sans"}}>
                    <span>28 Egbu Road</span>
                    <span>mintizers@gmail.com{/* contact@adminting.com */}</span>
                    <span>+2349165475190</span>
                </div>
            </div>
        </div>
        <div className='row' id='footlast'>{new Date().getFullYear()} - AdMinting Ltd - RC: 7555686</div>
    </>
  )
}

export default Footer;