import React from 'react';
import {Helmet} from "react-helmet";

import Nav from "../Components/Nav";
import Footer from '../Components/Footer';

import '../Styles/Pages/Terms.css';

function Terms() {
  return (
    <div className='Terms'>
        <Helmet>
            <title>Terms - AdMinting</title>
        </Helmet>
        <Nav/>
        <div className='row' id='prihead'>
            <h2>Terms Of Service</h2>
        </div>
        <div id='termbd'>
          <h2 style={{textAlign: "center"}}>These Terms and Conditions ("Terms") govern your use of the AdMinting mobile application ("App") and services provided by AdMinting, a digital advertising platform headquartered in Nigeria. <br/><br/>By signing up and using our App, you agree to comply with these Terms. Please read them carefully before proceeding.</h2>
          <strong>1. Registration and Account</strong> 
          <ol>You must provide accurate and complete information during the registration process.</ol>
          <ol>You must be at least 18 years old to use our App. By using the App, you confirm that you are at least 18 years old.</ol>
          <ol>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.</ol>
          
          <strong>2. Services Provided</strong>
          <ol>AdMinting provides a marketplace for advertisers to connect with digital communities, influencers, and content creators ("Users") to share advertisements and content on their platforms.</ol>
          <ol>Users agree to promote advertiser content to their networks and audiences and earn compensation based on the engagement and results they drive for the advertiser.</ol>
          <ol>AdMinting aggregates digital communities, influencers, and content creators and facilitates partnerships between them and advertisers.</ol>
          
          <strong>3. User Conduct</strong>
          <ol>Users agree to use the App and services only for lawful purposes and in a manner consistent with all applicable laws and regulations.</ol>
          <ol>Users agree not to engage in any of the following prohibited activities:</ol>
            <ol>- Violating any local, state, national, or international law or regulation.</ol>
              <ol>- Posting or transmitting any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</ol>
              <ol>- Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</ol>
              <ol>- Interfering with the operation of the App or any user’s enjoyment of it, including by uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code.</ol>
              <ol>- Attempting to gain unauthorized access to the App, other user accounts, or any related systems or networks.</ol>
            
            <strong>4. Payment and Compensation</strong>
            <ol>Users will receive compensation based on the engagement and results they generate for advertisers as specified in individual campaigns.</ol>
            <ol>AdMinting will facilitate payments to Users based on campaign performance metrics and terms.</ol>
            <ol>All payments are subject to applicable local laws and regulations, including taxation requirements.</ol>
            
            <strong>5. Intellectual Property</strong>
            <ol>The App and its original content, features, and functionality are owned by AdMinting and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</ol>
            <ol>Users retain ownership of their content but grant AdMinting and advertisers a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and display the content for advertising purposes.</ol>
            
            <strong>6. Privacy</strong>
            <ol>Your privacy is important to us. Our Privacy Policy explains how we collect, use, and disclose information about you. By using the App, you consent to our Privacy Policy.</ol>
            
            <strong>7. Termination</strong>
            <ol>AdMinting reserves the right to terminate or suspend your access to the App immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</ol>
            <ol>Upon termination, your right to use the App will immediately cease. If you wish to terminate your account, you may simply discontinue using the App.</ol>
            <ol>Disclaimer of Warranty and Limitation of Liability</ol>
            <ol>The App is provided on an "as is" and "as available" basis. AdMinting makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</ol>
            <ol>In no event shall AdMinting be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the App.</ol>
            <ol>AdMinting’s total liability to you for any damages, losses, and causes of action arising out of or relating to these Terms or your use of the App shall not exceed the amount paid by you, if any, for accessing the App during the twelve (12) months immediately preceding the date of the claim.</ol>

            <strong>9. Governing Law</strong>
            <ol>These Terms shall be governed and construed in accordance with the laws of Nigeria, without regard to its conflict of law provisions.</ol>

            <strong>10. Dispute Resolution</strong>
            <ol>Any dispute arising out of or in connection with these Terms shall be resolved through good faith negotiation between the parties. If the dispute cannot be resolved through negotiation, it shall be submitted to binding arbitration in accordance with the arbitration rules of the Nigeria Arbitration Association.</ol>
            
            <strong>11. Changes to Terms</strong>
            <ol>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</ol>

            <strong>12. Contact Us</strong>
            <ol>If you have any questions about these Terms, please contact us at [contact email].</ol>

            <strong>By signing up and using our App, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with these Terms, you may not use the App.</strong>
        </div>
        <Footer/>
    </div>
  )
}

export default Terms