import './App.css';
import { Routes, BrowserRouter, Route } from 'react-router-dom';

import Home from './Pages/Home';
import About from './Pages/About';
import FAQs from './Pages/FAQs';
import Calculator  from './Pages/Calculator';
import Privacy  from './Pages/Privacy';
import Terms  from './Pages/Terms';
import Commguide  from './Pages/Community_guide';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='' element={<Home/>}/>
          <Route exact path='/about' element={<About/>}/>
          <Route exact path='/faqs' element={<FAQs/>}/>
          <Route exact path='/calculator' element={<Calculator/>}/>
          <Route exact path='/terms' element={<Terms/>}/> 
          <Route exact path='/privacy' element={<Privacy/>}/> 
          <Route exact path='/community_guidelines' element={<Commguide/>}/> 
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
