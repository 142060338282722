import React from 'react';

import Nav from "../Components/Nav";
import Team from "../Components/Team";
import Footer from '../Components/Footer';

function About() {
  return (
    <div className='About' style={{backgroundColor: "#FAF9F6"}}>
        <Nav/>
        <Team/>
        <div id='termbd'>
            
        </div>
        <Footer/>
    </div>
  )
}

export default About