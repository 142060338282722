import React from 'react'

import gplay from '../Images/Google Play.png';
import appstr from '../Images/App Store.png'

import '../Styles/Components/Join.css';

function Join() {
  return (
    <div id='body6'>
        <div id='inner'>
            <h3>Join Our Growing Community</h3>
            <span id='joinwrap'>
                <p>Join over 1,000  advertisers and promoters who trust AdMinting for their advertising needs.</p>
                <div id='imgwrap'>
                  <img alt='' src={appstr}/>
                  <img alt='' src={gplay}/>
                </div>
            </span>
        </div>
    </div>
  )
}

export default Join;